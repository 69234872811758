import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { params, string } from '@thd-nucleus/data-sources';
import {
  Typography, TextField, LoadingButton
} from '@one-thd/sui-atomic-components';
import helper from '../../helper';
import { useEmailProduct } from '../../hooks/useEmailProduct';

const ShareBeltEmail = ({ itemId }) => {
  const [message, setMessage] = useState({});
  const [uiState, setUIState] = useState({
    active: false,
    bttn: false,
    message: '',
    toggleClass: ''
  });
  const [toStatus, setToStatus] = useState(null);
  const [fromStatus, setFromStatus] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { onClickSubmitHandler } = useEmailProduct({
    itemId, message, uiState, setUIState, setShowSuccessMessage
  });
  const tohandleChange = (event) => {
    const text = event.target.value;
    setMessage({
      ...message,
      recepient: helper.validateEmail(text),
      recepientEmailText: text
    });
    setToStatus(helper.validateEmail(text) ? 'success' : 'error');
    setShowSuccessMessage(false);

  };
  const fromhandleChange = (event) => {
    const text = event.target.value;
    setMessage({
      ...message,
      sender: helper.validateEmail(text),
      senderEmailText: text
    });
    setFromStatus(helper.validateEmail(text) ? 'success' : 'error');
    setShowSuccessMessage(false);
  };
  return (
    <div>
      <Typography variant="body-xs">
        Pass it along to someone you know! We&apos;ll
        send them a quick note with the product details.
      </Typography>
      <form>
        <span className="sui-relative sui-pb-2 sui-pt-2 sui-block">
          <TextField
            fullWidth
            label="To"
            placeholder="yourfriend@domain.com"
            value={message.recepientEmailText}
            onChange={tohandleChange}
            status={toStatus}
            statusMessage={toStatus === 'error'
              ? 'Please provide your email address, formatted as you@example.com'
              : ''}
          />
        </span>
        <span className="sui-relative sui-pb-2 sui-pt-2 sui-block">
          <TextField
            fullWidth
            label="From"
            placeholder="youremail@domain.com"
            value={message.senderEmailText}
            onChange={fromhandleChange}
            status={fromStatus}
            statusMessage={fromStatus === 'error'
              ? 'Please provide your email address, formatted as you@example.com'
              : ''}
          />
        </span>
        <div className="sui-mt-4 sui-mb-4 sui-ml-4">
          <div className="sui-float-left sui-max-w-md">
            {showSuccessMessage
              && (
                <Typography
                  color={uiState.toggleClass === 'success' ? 'success' : 'danger'}
                >
                  {uiState.message}
                </Typography>
              )}
          </div>
          <div className="sui-float-right">
            <LoadingButton
              onClick={onClickSubmitHandler}
              variant="primary"
              loading={uiState.active}
              disabled={(!message.recepient) || (!message.sender)}
            >
              Send Email
            </LoadingButton>
          </div>
        </div>
      </form>
    </div>
  );
};

ShareBeltEmail.displayName = 'ShareBeltEmail';

ShareBeltEmail.propTypes = {
  itemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])
};

ShareBeltEmail.defaultProps = {
  itemId: null
};

ShareBeltEmail.dataModel = {
  emailProduct: params({
    fromAddress: string().isRequired(),
    toAddress: string().isRequired(),
    itemId: string().isRequired()
  }).shape({
    responseMessageId: string(),
    message: string()
  })
};

export default ShareBeltEmail;
