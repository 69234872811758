import { useEffect } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';

export const useEmailProduct = ({
  itemId,
  message: { recepientEmailText = undefined, senderEmailText = undefined },
  uiState,
  setUIState,
  setShowSuccessMessage
}) => {
  const [emailProductRequest, emailProductResponse] = useDataModel('emailProduct', {}, true);

  useEffect(() => {
    const uiProps = { active: false, bttn: false };
    if (emailProductResponse?.error || emailProductResponse?.errors) {
      setUIState({
        ...uiProps,
        message: 'We’re sorry, an unexpected error prevented your item from being shared. Please try again.',
        toggleClass: 'error'
      });
      setShowSuccessMessage(true);

    } else if (emailProductResponse?.data?.emailProduct) {
      setUIState({
        ...uiProps,
        message: `Great! You’ve shared this item with ${recepientEmailText}`,
        toggleClass: 'success'
      });
      setShowSuccessMessage(true);
    }
  }, [emailProductResponse]);

  const onClickSubmitHandler = (evt) => {
    evt.preventDefault();

    setUIState({ ...uiState, active: true, bttn: true });
    const variables = { itemId, fromAddress: senderEmailText, toAddress: recepientEmailText };
    emailProductRequest({ variables });
  };

  return { onClickSubmitHandler };
};