import React from 'react';
import {
  bool, func, oneOfType, number, string
} from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Modal, ModalHeader, ModalBody } from '@one-thd/sui-atomic-components';
import ShareBeltEmail from '../ShareBeltEmail/ShareBeltEmail';

const ShareToEmail = ({
  closeOverlays,
  emailOverlayOpen,
  itemId,
  storeId,
}) => {

  return (
    <Modal
      open={emailOverlayOpen}
      onClose={closeOverlays}
    >
      <ModalHeader onClose={closeOverlays}>Email This Product</ModalHeader>
      <ModalBody>
        <QueryProvider cacheKey="email-product">
          <ShareBeltEmail itemId={itemId} />
        </QueryProvider>
      </ModalBody>
    </Modal>
  );
};

ShareToEmail.propTypes = {
  closeOverlays: func.isRequired,
  emailOverlayOpen: bool,
  itemId: oneOfType([string, number]),
  storeId: string
};

ShareToEmail.defaultProps = {
  emailOverlayOpen: false,
  itemId: null,
  storeId: null
};

export default ShareToEmail;
